import { defineNuxtPlugin } from '#app'
import { createVuetify } from 'vuetify'

const isDev = process.env.NODE_ENV === "development"
const options = JSON.parse('{"ssr":true,"treeshaking":true,"useVuetifyLabs":true,"icons":{"defaultSet":"mdi"},"defaults":{"VCard":{"rounded":"lg","border":"sm","color":"background"},"VAppBar":{"color":"background","elevation":0,"border":"sm"},"VAlert":{"variant":"outlined"},"VTable":{"class":"bg-background"},"VDialog":{"scrim":"black"},"VTextField":{"variant":"outlined","color":"grey"},"VTextarea":{"variant":"outlined","color":"grey"},"VSelect":{"variant":"outlined","color":"grey"},"VAutocomplete":{"variant":"outlined","color":"grey"}},"theme":{"defaultTheme":"dev","variations":{"colors":["primary","secondary"],"darken":4,"lighten":4},"themes":{"defaultTheme":{"dark":false,"colors":{"background":"#d9d9de","surface":"#2d2d2d","primary":"#3f51b5","secondary":"#03dac6","error":"#f44336","info":"#2196F3","success":"#59ce5d","warning":"#fb8c00"}},"darkTheme":{"dark":true,"colors":{"background":"#15202b","surface":"#15202b","primary":"#3f51b5","secondary":"#03dac6","error":"#f44336","info":"#2196F3","success":"#4caf50","warning":"#fb8c00"}},"dev":{"dark":true,"colors":{"background":"#0d121a","surface":"#15202b","primary":"#3f51b5","secondary":"#03dac6","error":"#f44336","info":"#2196F3","success":"#59ce5d","warning":"#fb8c00"}}}}}')

''

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  if (!process.server && isDev) {
    // eslint-disable-next-line no-console
    console.log('💚 Initialized Vuetify 3', vuetify)
  }

  return {
    provide: {
      vuetify
    }
  }
})
