import revive_payload_client_vgZibDRyNp from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_FBWIbowDvE from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_v1kwOVdbjv from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_57AhMvuc5W from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.1.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_39ktQ9Z6Qy from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_pVl2RSrl5Y from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.3.7/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Zm90w9UCoj from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_1L1d3mmRNX from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@1.1.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import templates_plugin_3ef7a326_NoVgCEctf7 from "/vercel/path0/.nuxt/templates.plugin.3ef7a326.mts";
import composition_rJzsahaCMl from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_@vercel+kv@0.2.4_vue-router@4.2.5_vue@3.3.7/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_AOjst4RNr4 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_@vercel+kv@0.2.4_vue-router@4.2.5_vue@3.3.7/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_redirect_YkttPkT127 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.1.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import plugin_v2vBc5WWLo from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.0.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_LylemO5mZJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_HrupWyu2nY from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.0_@vercel+kv@0.2.4_vite@4.5.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  revive_payload_client_vgZibDRyNp,
  unhead_FBWIbowDvE,
  router_v1kwOVdbjv,
  supabase_client_57AhMvuc5W,
  payload_client_39ktQ9Z6Qy,
  plugin_vue3_pVl2RSrl5Y,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Zm90w9UCoj,
  plugin_client_1L1d3mmRNX,
  templates_plugin_3ef7a326_NoVgCEctf7,
  composition_rJzsahaCMl,
  i18n_AOjst4RNr4,
  auth_redirect_YkttPkT127,
  plugin_v2vBc5WWLo,
  chunk_reload_client_LylemO5mZJ,
  check_outdated_build_client_HrupWyu2nY
]