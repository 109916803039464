import { default as settingsvKdymHkKMIMeta } from "/vercel/path0/pages/app/account/settings.vue?macro=true";
import { default as cancelledQGiEBUJXC8Meta } from "/vercel/path0/pages/app/billing/checkout/cancelled.vue?macro=true";
import { default as successF0uV2b6elmMeta } from "/vercel/path0/pages/app/billing/checkout/success.vue?macro=true";
import { default as indexom2Z7YjHVrMeta } from "/vercel/path0/pages/app/billing/index.vue?macro=true";
import { default as top_45upr3fxTd12ccMeta } from "/vercel/path0/pages/app/billing/top-up.vue?macro=true";
import { default as your_45pricesLaWUTXOoV8Meta } from "/vercel/path0/pages/app/billing/your-prices.vue?macro=true";
import { default as complete_45signupm1rtSzvtPTMeta } from "/vercel/path0/pages/app/complete-signup.vue?macro=true";
import { default as indexlDnY9KtD0eMeta } from "/vercel/path0/pages/app/contacts/index.vue?macro=true";
import { default as indexWk8Hi46KRAMeta } from "/vercel/path0/pages/app/index.vue?macro=true";
import { default as composeUuH5dxoqC4Meta } from "/vercel/path0/pages/app/messages/compose.vue?macro=true";
import { default as indexTHotoWJaQtMeta } from "/vercel/path0/pages/app/messages/index.vue?macro=true";
import { default as indexPBZtPVsOzcMeta } from "/vercel/path0/pages/app/sender-ids/index.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as sign_45upHNIilXjClyMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
export default [
  {
    name: settingsvKdymHkKMIMeta?.name ?? "app-account-settings",
    path: settingsvKdymHkKMIMeta?.path ?? "/app/account/settings",
    meta: settingsvKdymHkKMIMeta || {},
    alias: settingsvKdymHkKMIMeta?.alias || [],
    redirect: settingsvKdymHkKMIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/account/settings.vue").then(m => m.default || m)
  },
  {
    name: cancelledQGiEBUJXC8Meta?.name ?? "app-billing-checkout-cancelled",
    path: cancelledQGiEBUJXC8Meta?.path ?? "/app/billing/checkout/cancelled",
    meta: cancelledQGiEBUJXC8Meta || {},
    alias: cancelledQGiEBUJXC8Meta?.alias || [],
    redirect: cancelledQGiEBUJXC8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/billing/checkout/cancelled.vue").then(m => m.default || m)
  },
  {
    name: successF0uV2b6elmMeta?.name ?? "app-billing-checkout-success",
    path: successF0uV2b6elmMeta?.path ?? "/app/billing/checkout/success",
    meta: successF0uV2b6elmMeta || {},
    alias: successF0uV2b6elmMeta?.alias || [],
    redirect: successF0uV2b6elmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/billing/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: indexom2Z7YjHVrMeta?.name ?? "app-billing",
    path: indexom2Z7YjHVrMeta?.path ?? "/app/billing",
    meta: indexom2Z7YjHVrMeta || {},
    alias: indexom2Z7YjHVrMeta?.alias || [],
    redirect: indexom2Z7YjHVrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/billing/index.vue").then(m => m.default || m)
  },
  {
    name: top_45upr3fxTd12ccMeta?.name ?? "app-billing-top-up",
    path: top_45upr3fxTd12ccMeta?.path ?? "/app/billing/top-up",
    meta: top_45upr3fxTd12ccMeta || {},
    alias: top_45upr3fxTd12ccMeta?.alias || [],
    redirect: top_45upr3fxTd12ccMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/billing/top-up.vue").then(m => m.default || m)
  },
  {
    name: your_45pricesLaWUTXOoV8Meta?.name ?? "app-billing-your-prices",
    path: your_45pricesLaWUTXOoV8Meta?.path ?? "/app/billing/your-prices",
    meta: your_45pricesLaWUTXOoV8Meta || {},
    alias: your_45pricesLaWUTXOoV8Meta?.alias || [],
    redirect: your_45pricesLaWUTXOoV8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/billing/your-prices.vue").then(m => m.default || m)
  },
  {
    name: complete_45signupm1rtSzvtPTMeta?.name ?? "app-complete-signup",
    path: complete_45signupm1rtSzvtPTMeta?.path ?? "/app/complete-signup",
    meta: complete_45signupm1rtSzvtPTMeta || {},
    alias: complete_45signupm1rtSzvtPTMeta?.alias || [],
    redirect: complete_45signupm1rtSzvtPTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/complete-signup.vue").then(m => m.default || m)
  },
  {
    name: indexlDnY9KtD0eMeta?.name ?? "app-contacts",
    path: indexlDnY9KtD0eMeta?.path ?? "/app/contacts",
    meta: indexlDnY9KtD0eMeta || {},
    alias: indexlDnY9KtD0eMeta?.alias || [],
    redirect: indexlDnY9KtD0eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexWk8Hi46KRAMeta?.name ?? "app",
    path: indexWk8Hi46KRAMeta?.path ?? "/app",
    meta: indexWk8Hi46KRAMeta || {},
    alias: indexWk8Hi46KRAMeta?.alias || [],
    redirect: indexWk8Hi46KRAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: composeUuH5dxoqC4Meta?.name ?? "app-messages-compose",
    path: composeUuH5dxoqC4Meta?.path ?? "/app/messages/compose",
    meta: composeUuH5dxoqC4Meta || {},
    alias: composeUuH5dxoqC4Meta?.alias || [],
    redirect: composeUuH5dxoqC4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/messages/compose.vue").then(m => m.default || m)
  },
  {
    name: indexTHotoWJaQtMeta?.name ?? "app-messages",
    path: indexTHotoWJaQtMeta?.path ?? "/app/messages",
    meta: indexTHotoWJaQtMeta || {},
    alias: indexTHotoWJaQtMeta?.alias || [],
    redirect: indexTHotoWJaQtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/messages/index.vue").then(m => m.default || m)
  },
  {
    name: indexPBZtPVsOzcMeta?.name ?? "app-sender-ids",
    path: indexPBZtPVsOzcMeta?.path ?? "/app/sender-ids",
    meta: indexPBZtPVsOzcMeta || {},
    alias: indexPBZtPVsOzcMeta?.alias || [],
    redirect: indexPBZtPVsOzcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/sender-ids/index.vue").then(m => m.default || m)
  },
  {
    name: confirm2uAIxw9fKZMeta?.name ?? "confirm",
    path: confirm2uAIxw9fKZMeta?.path ?? "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    alias: confirm2uAIxw9fKZMeta?.alias || [],
    redirect: confirm2uAIxw9fKZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutBqDDZXgXsyMeta?.name ?? "logout",
    path: logoutBqDDZXgXsyMeta?.path ?? "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    alias: logoutBqDDZXgXsyMeta?.alias || [],
    redirect: logoutBqDDZXgXsyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: sign_45upHNIilXjClyMeta?.name ?? "sign-up",
    path: sign_45upHNIilXjClyMeta?.path ?? "/sign-up",
    meta: sign_45upHNIilXjClyMeta || {},
    alias: sign_45upHNIilXjClyMeta?.alias || [],
    redirect: sign_45upHNIilXjClyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/sign-up.vue").then(m => m.default || m)
  }
]