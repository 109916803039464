import {defineStore} from "#imports";
import {watch, ref} from '#imports';
import type {BillingAccount, EntityAccount} from "../index";
import type {User} from "@supabase/gotrue-js";
import {navigateTo, useThrottleFn} from '#imports'
import {Routes} from "~/lib/navigation";


export const useAccountsStore = defineStore('accounts', () => {
    const sessionToken = ref<any | undefined>(undefined);
    const supaUser = ref<User | undefined>(undefined);

    const _current = ref<EntityAccount | null>(null);
    const current = computed<EntityAccount | null>({
        get: () => {
            const supabaseUser = useSupabaseUser();
            if (!_current.value && supabaseUser.value) {
                loadCurrentAccount().catch(console.error);
            }

            return _current.value;
        },
        set: (value) => _current.value = value
    });
    const isLoggedIn = computed((): boolean => {
        const supabaseUser = useSupabaseUser();
        return supabaseUser.value !== null;
    });
    const currentBillingAccount = computed((): BillingAccount | undefined => current.value ? current.value.billing_accounts[0] ?? undefined : undefined)

    const sessionPresent = async () => {
        if (!sessionToken.value) {
            const supabase = useSupabaseClient();
            const session = supabase.auth.getSession();
            const token = await session;

            if (!token.data.session) {
                return false;
            }
            sessionToken.value = token.data.session;
        }
        return true;
    }

    const getCurrentAccount = async (): Promise<any> => {
        const supabaseUser = useSupabaseUser();
        if (!supabaseUser.value || !await sessionPresent()) {
            return null;
        } else {
            supaUser.value = supabaseUser.value;
        }

        const account = await $fetch<EntityAccount>('/api/account/me');

        if (!account) {
            navigateTo(Routes.login)
            // document.location.reload()
            // await signOut();
            // alert('No value in the data.value OR data.value.account?')
            return null;
        }

        return account;
    }

    const loadCurrentAccount = useThrottleFn(async () => {
        current.value = await getCurrentAccount();

        if (current.value && !current.value.active) {
            if (process.client) {
                navigateTo(Routes.app.signup.complete)
            }
            return null;
        }

        return current.value;
    });

    watch(current, async () => {
        const supabaseUser = useSupabaseUser();
        if (!current.value && supabaseUser.value) {
            await loadCurrentAccount();
            return;
        }
        if (current.value && supabaseUser.value && supabaseUser.value.id !== current.value.id) {
            await loadCurrentAccount();
        }
    });

    const signOut = async () => {
        current.value = null;
        try {
            const supabase = useSupabaseClient();
            await supabase.auth.signOut();
        } finally {
            localStorage.clear();
        }
    }

    return {
        signOut,
        user: supaUser,
        current,
        currentBillingAccount,
        isLoggedIn,
        getCurrentAccount,
        loadCurrentAccount,
    }
});