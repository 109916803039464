import {Routes} from "~/lib/navigation";
import {navigateTo, storeToRefs} from '#imports'
import {useAccountsStore} from "~/lib/accounts";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (to.path.startsWith(Routes.app.home) && to.path !== Routes.app.signup.complete) {
        const accounts = useAccountsStore();
        const {current} = storeToRefs(accounts);

        if (current.value && current.value.active !== undefined && !current.value.active) {
            return navigateTo(Routes.app.signup.complete);
        }
    }
});