export * from './store/navigation';

export const Routes = {
    home: '/',
    login: '/login',
    signUp: '/sign-up',
    logout: '/logout',
    confirm: '/confirm',
    pricing: '/pricing',
    app: {
        home: '/app',
        signup: {
            complete: '/app/complete-signup',
        },
        contacts: {
            home: '/app/contacts',
        },
        messages: {
            home: '/app/messages',
            compose: '/app/messages/compose',
            sent: '/app/messages/sent',
        },
        billing: {
            home: '/app/billing',
            topUp: '/app/billing/top-up',
            checkout:{
                success: '/app/billing/checkout/success',
                cancelled: '/app/billing/checkout/cancelled',
            }
        },
        account:{
            settings: '/app/account/settings',
        },
        senderIds:{
            home: '/app/sender-ids',
        }
    }
}