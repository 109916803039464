export type EntityTransactionStatus = 'pending' | 'cancelled' | 'expired' | 'completed';

export interface EntityTransaction {
    id: string;
    account_id: string;
    amount: number;
    amount_tkn: number;
    type: 'credit' | 'debit';
    status: EntityTransactionStatus;
    description?: string;
    status_text?: {
        [key in EntityTransactionStatus]?: string;
    };
    timestamps?: {
        [key in EntityTransactionStatus]?: string;
    };
    processor_id?: string;
    created_at: string;
    updated_at?: string;
}

export const useTransactionsStore = defineStore('transactions', () => {
    const myTransactions = ref<EntityTransaction[]>();

    const fetchTransactions = async (): Promise<EntityTransaction[]> => {

        const db = useSupabaseClient();
        const {data, error} = await db.from('transactions')
            .select<'*', EntityTransaction>();

        if (error || !data) {
            throw new Error('Cannot fetch transactions');
        }

        return data ?? [];
    }

    const loadTransactions = async () => myTransactions.value = await fetchTransactions();


    return {
        myTransactions,
        fetchTransactions,
        loadTransactions,
    }
});
