import {type MaybeRef, onMounted, ref, toValue} from 'vue';
import {useDisplay} from "vuetify";

export const useNavigationStore = defineStore('navigation', () => {
    //Other composables...
    const display = useDisplay();

    //Data
    const displayNavigation = ref<boolean>(false);
    const displayNavigationIsUserDefined = ref<boolean>(false);

    const initializeNavigation = (): void => {
        if (display.mdAndUp.value && !displayNavigationIsUserDefined.value && !displayNavigation.value) {
            displayNavigation.value = true;
            return;
        }

        if (display.smAndDown.value && !displayNavigationIsUserDefined.value && displayNavigation.value) {
            displayNavigation.value = false;
            return;
        }
    }
    if (process.client) {
        onMounted(initializeNavigation);
    }


    return {
        displayNavigation,
        displayNavigationIsUserDefined,
    }
});